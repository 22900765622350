import { eventQBus } from "../types/EventQBus";
import { Page, TilelistLoadEvent } from "../types/Tilelist";
import { clean } from "../utils/Core";
import { isSamePage, pagedUrl } from "../utils/Global";
import { animation } from "@otto-ec/global-resources/animation";
import { core } from "@otto-ec/global-resources/core";
import { misc } from "@otto-ec/global-resources/misc";

function setMissingLayout(page: Page) {
  /*                              */
  if (!page["l"]) {
    /*                                          */
    const pagingLink = document.querySelector(".js_pagingLink") as HTMLElement;

    if (!!pagingLink && !!pagingLink.dataset["page"]) {
      try {
        const nextPageObject = JSON.parse(pagingLink.dataset["page"]);
        if (!!nextPageObject && !!nextPageObject["l"]) {
          page["l"] = nextPageObject["l"];
        }
      } catch (e) {
        /*        */
      }
    }
  }

  return page;
}

function extractHtmlFragment(response: Response) {
  if (response.status !== 200) {
    throw Error(`Failed to retrieve tilelist: ${response.status} ${response.statusText}`);
  }

  return response.text();
}

function replaceTileList(html: string) {
  const range = document.createRange();
  const referenceNode = document.getElementById("reptile-tilelist-bracket");

  if (referenceNode) {
    /*                                 */
    /*                                                               */
    range.selectNode(referenceNode);
    const fragment = range.createContextualFragment(html);
    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                                                                     */
    const contentNode: HTMLElement | null = document.getElementById("reptile-content");

    if (contentNode) {
      window.preload_polyfill_invoke(contentNode);
    }
  }
}

function scrollToTop(data: Partial<TilelistLoadEvent>) {
  if (data.historic || data.shouldScroll) {
    animation.scrollTo(0, 0);
  }
}

function toggleTilelistLoadingClass(force: boolean) {
  const tilelistElement = document.querySelector(".reptile_tilelist");

  if (tilelistElement) {
    tilelistElement.classList.toggle("loading", force);
  }
}

function getCurrentPage() {
  const searchResult = document.getElementById("reptile-search-result");

  const tileList = document.getElementById("reptile-tilelist");
  if (tileList) {
    if (tileList.classList.contains("reptile_searchResult--hideProductComparison")) {
      tileList.classList.remove("reptile_tileList--showCompare");
    }
    if (
      !tileList.classList.contains("reptile_searchResult--hideProductComparison") &&
      !tileList.classList.contains("reptile_tileList--showCompare")
    ) {
      tileList.classList.add("reptile_tileList--showCompare");
    }
  }
  return clean(JSON.parse((searchResult && searchResult.dataset.page) || "{}")) as Page;
}

function getTrackingQuery(createEvent: boolean) {
  return createEvent || !window.o_tracking || !window.o_tracking.bct
    ? ""
    : "&" + window.o_tracking.bct.getMergeParameters();
}

function getSfid() {
  return document.querySelector<HTMLElement>(".ts-bct")?.dataset.ts_sfid || misc.guid();
}

function removeOriginalSearchTermQueryParam() {
  const url = new URL(window.location.href);
  if (url.searchParams && url.searchParams.has("st")) {
    url.searchParams.delete("st");
    eventQBus.emit("ftfind.history.merge", { url: url.href });
  }
}

function onTilelistLoad(event: TilelistLoadEvent) {
  const { rule, page, tsLink, mergeOnPi, redirect, shouldTrackFeatureToPI, ...rest } = event,
    cleanedPage = clean(page),
    createEvent = !mergeOnPi,
    currentPage = getCurrentPage();

  if (isSamePage(currentPage, cleanedPage) && currentPage === rule) {
    return;
  }

  const query = core.serialize({
    rule: rule,
    ...cleanedPage,
  });

  const sfid = getSfid();
  const pageCluster = document.documentElement.dataset["pagecluster"] || "";
  const tracking =
    core.serialize({
      san_ListEventType: "tilelistview",
      ot_PageCluster: pageCluster,
      /*                                                                                            */
      /*                                         */
      ot_VisibleUrl: redirect || pagedUrl(cleanedPage, window.location.pathname, window.location.search),
      /*                                                                      */
      ...(createEvent && { ts_Type: "event", eventMergeId: sfid }),
      ...tsLink,
    }) || "";
  const trackingQuery = getTrackingQuery(createEvent);

  const dummySkeleton = document.getElementById("reptile_dummy_skeleton");
  const initalSkeleton = document.getElementById("reptile_initial_skeleton");
  if (dummySkeleton) {
      document.querySelectorAll(".find_tile").forEach((target) => (target.innerHTML = dummySkeleton.innerHTML));
  } else if (initalSkeleton) {
    toggleTilelistLoadingClass(false);
  } else {
    toggleTilelistLoadingClass(true);
  }

  fetch(`/everglades/tilelist?${query}${trackingQuery}`, {
    headers: {
      /*                                                                                     */
      /*                                                                                             */
      /*                                                                               */
      "X-Find-Trackable": tracking,
      /*                                                                            */
      "X-Tracking-Sfid": sfid,
    },
  })
    .then(extractHtmlFragment)
    .then(replaceTileList)
    .then(() => scrollToTop(rest))
    .then(removeOriginalSearchTermQueryParam)
    .then(() => {
      eventQBus.emit("ftfind.tilelist.loaded", {
        rule: rule,
        page: setMissingLayout(page),
        tsLink: tsLink,
        ...(redirect && { redirect: redirect }),
        ...(createEvent && { eventMergeId: sfid }),
        shouldTrackFeatureToPI: shouldTrackFeatureToPI,
        ...rest,
      });
    })
    .catch(() => {
      toggleTilelistLoadingClass(false);
      eventQBus.emit("ftfind.advice.show", {
        message: "Wir bitten um Entschuldigung. Das hat leider nicht funktioniert. Bitte versuche es später erneut.",
      });
    });
}

export default function registerTilelistLoadingEvent() {
  eventQBus.on("ftfind.tilelist.load", onTilelistLoad);
}
